import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import BaseLayout from "../components/base-layout";
import Container from "../components/container";
import Content from "../components/content";
import CenteredContentBox from "../components/centered-content-box";
import QuoteHero from "../components/quote-hero";
import Spacer from "../components/spacer";
import { scale } from "../utils/typography";
import { getBodyStyleByHash } from "../utils/styles";

class ContentPageTemplate extends React.Component {
  render() {
    const contentPage = this.props.data.markdownRemark;
    return (
      <BaseLayout>
        <div className="content-page">
          <Helmet title={contentPage.frontmatter.title}>
            <body
              css={{
                ...getBodyStyleByHash(contentPage.frontmatter.slug),
              }}
            />
          </Helmet>
          <Spacer size={2} />
          <Container>
            <QuoteHero subtitle={contentPage.frontmatter.subtitle}>
              {contentPage.frontmatter.title}
            </QuoteHero>
          </Container>
          <Spacer size={2} />
          <Container>
            <CenteredContentBox className="content">
              <Spacer size={2} />
              <Content
                html={contentPage.html}
                css={{
                  "& > p:first-child": {
                    ...scale(0.4),
                  },
                }}
              />
              <Spacer size={2} />
            </CenteredContentBox>
            <Spacer size={2} />
          </Container>
        </div>
      </BaseLayout>
    );
  }
}

export default ContentPageTemplate;

export const pageQuery = graphql`
  query ContentPageBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      fields {
        path
      }
      frontmatter {
        title
        slug
      }
    }
  }
`;
